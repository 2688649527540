export function getServerType() {
  return process.env.REACT_APP_SERVER_TYPE;
}
export default class Config {
  isOnDebugMode =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  getBaseURL() {
    let e = process.env;
    const url = this.isOnDebugMode ? e.REACT_APP_BASE_URL_DEV : e.REACT_APP_BASE_URL;
    return url;
  }

  getGameType() {
    let url_string = (window as any).location.href; //window.location.href
    let url = new URL(url_string);
    return url.searchParams.get("GameType");
  }
}
