
// components
import Game from "./pages/Game/Game";
import EntryScreen from "./pages/EntryScreen/EntryScreen";
import QuizSchedule from "./pages/QuizSchedule/QuizSchedule";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import GameEnd from "./pages/GameEnd/GameEnd";
import { getServerType } from "./Config";
import Close from "./pages/Close/Close";
export function routes() {

  if (getServerType() === 'playtech') {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<QuizSchedule />} />
          <Route
            path="/entry/:gameType"
            element={<EntryScreen />}
          />
          <Route path="/game/:game/:gameId" element={<Game />} />
          <Route path="/game-end/:game/:gameId" element={<GameEnd />} />
          <Route path="/close" element={<Close />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<QuizSchedule />} />
          <Route
            path="/entry/:game/:isGameNotFound?"
            element={<EntryScreen />}
          />
          <Route path="/game/:game/:gameId" element={<Game />} />
          <Route path="/game-end/:game/:gameId" element={<GameEnd />} />
        </Routes>
      </BrowserRouter>
    );
  }



}
