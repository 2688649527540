import React from "react";
import ReactDOM from "react-dom";
import Main from "./pages/Main";
import { Provider } from "mobx-react";
import { SocketState, GameState, ChatMessages } from "./store/GameStateStore";
import Store from "./store/Store";
import {Amplify} from "aws-amplify";
import { createRoot } from 'react-dom/client';

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: 'https://rjrmaxgxv5g7rprksvtq2g4l6a.appsync-api.us-east-2.amazonaws.com/graphql',
      region: 'us-east-2',
      defaultAuthMode: 'apiKey',
      apiKey: 'da2-wmoa5cfamrcnlouvchh2oipo54'
    }
  }
});

const container = document.getElementById('root') as any;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider
    SocketState={SocketState}
    GameState={GameState}
    ChatMessages={ChatMessages}
  >
    <Store>
      <Main />
    </Store>
  </Provider>);


// const container  = (
//   <Provider
//     SocketState={SocketState}
//     GameState={GameState}
//     ChatMessages={ChatMessages}
//   >
//     <Store>
//       <Main />
//     </Store>
//   </Provider>,
//   document.getElementById("root")
// );


// serviceWorker.register();
