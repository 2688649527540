import React, { Component } from "react";
import ApiService from "../../services/ApiService";
import { getServerName } from "../../services/HelperMethodsService";
interface SendMessageProps {
  gameType: string;
}

export default class SendMessage extends Component<SendMessageProps> {
  state = {
    message: "",
    showMessage: false,
  };

  apiService = new ApiService();

   getNameFromUrl(){
    let url_string = window.location.href; //window.location.href
    let url = new URL(url_string);
    return  url.searchParams.get("username");
  }

  sendToChat(message: string) {
    if (message) {
      this.apiService.addChat({ message: message, server: getServerName() , name : this.getNameFromUrl()});
    }
  }

  textBoxUI() {
    let openClass = " open";
    if (!this.state.showMessage) {
      openClass = "";
    }

    return (
      <div className={"send-message-ui" + openClass}>
        <input
          className={"n" + this.props.gameType}
          type={"text"}
          maxLength={300}
          placeholder={"type message..."}
          value={this.state.message}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
              this.sendToChat(this.state.message);
              this.setState({
                showMessage: this.state.showMessage,
                message: "",
              });
            }
          }}
          onChange={(event) => {
            this.setState({
              showMessage: this.state.showMessage,
              message: event.target.value,
            });
          }}
        />
        <div
          className={"btn float-right send-button n" + this.props.gameType}
          onClick={() => {
            this.sendToChat(this.state.message);
            this.setState({
              showMessage: this.state.showMessage,
              message: "",
            });
          }}
        >
          <i className="fas fa-paper-plane" />
        </div>
      </div>
    );
  }

  toggleChat() {
    this.setState({
      showMessage: !this.state.showMessage,
      message: this.state.message,
    });
  }

  render() {
    let icon = <i className="fas fa-comment-dots" />;
    let openClass = "";

    if (this.state.showMessage) {
      icon = <i className="fas fa-times" />;
      openClass = "open";
    }
    return (
      <div className={"send-message-wrapper " + openClass}>
        <div
          className={"open-chat-button btn n" + this.props.gameType}
          onClick={() => {
            this.toggleChat();
          }}
        >
          {icon}
        </div>
        {this.textBoxUI()}
      </div>
    );
  }
}
