import React, { Fragment, useContext, useEffect, useState } from "react";
import "./Close.scss";
import BackgroundColor from "../../components/BackgroundColor/BackgroundColor";
import { getSkinId } from "../../services/HelperMethodsService";

export default function Close() {
  let gameType = getSkinId();

  return (
    <div className="layers">
      <div className={"game-end"}>
        <BackgroundColor gameType={gameType} showIcons={false}>
            <div className="text-close">Please close the game window.</div>
        </BackgroundColor>
      </div>
    </div>
  );
}
