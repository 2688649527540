import {
  GameTypesList,
  urlParamsAsync,
  urlParamsPlaytechAsync,
} from "../../services/HelperMethodsService";
import "swiper/css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./QuizSchedule.scss";
import "./ShootingStars.scss";
import { getServerType } from "../../Config";

export default function QuizSchedule() {
  const history = useNavigate();

  //TODO fix game type dry
  useEffect(() => {
   // console.log(getServerType(), "getServerType()");

    // check url param and redirec
    if (getServerType() === "playtech") {
      var ptUrl = urlParamsPlaytechAsync();
      //TODO: other game types
      if (ptUrl.gameId == "HOL") {
        history({
          pathname: "/entry/" + GameTypesList().nHollywood,
          search: ptUrl.search,
        });
      }
      if (ptUrl.gameId == "MUS") {
        history({
          pathname: "/entry/" + GameTypesList().nMusic,
          search: ptUrl.search,
        });
      }
      if (ptUrl.gameId == "00S") {
        history({
          pathname: "/entry/" + GameTypesList().n00s,
          search: ptUrl.search,
        });
      }
      if (ptUrl.gameId == "90s") {
        history({
          pathname: "/entry/" + GameTypesList().n90s,
          search: ptUrl.search,
        });
      }
      if (ptUrl.gameId == "80s") {
        history({
          pathname: "/entry/" + GameTypesList().n80s,
          search: ptUrl.search,
        });
      }
    } else {
      //bingo
      var bingoUrl = urlParamsAsync();
      if (bingoUrl.gameType == "23001") {
        history({
          pathname: "/entry/" + GameTypesList().nHollywood,
          search: new URL(window.location.href).search,
        });
      }
      if (bingoUrl.gameType == "23002") {
        history({
          pathname: "/entry/" + GameTypesList().n80s,
          search: new URL(window.location.href).search,
        });
      }
      if (bingoUrl.gameType == "23003") {
        history({
          pathname: "/entry/" + GameTypesList().n90s,
          search: new URL(window.location.href).search,
        });
      }
      if (bingoUrl.gameType == "23004") {
        history({
          pathname: "/entry/" + GameTypesList().n00s,
          search: new URL(window.location.href).search,
        });
      }
      if (bingoUrl.gameType == "23005") {
        history({
          pathname: "/entry/" + GameTypesList().nMusic,
          search: new URL(window.location.href).search,
        });
      }
    }
  });

  return <div className="quiz-schedule-wrapper"></div>;
}
