import React, { createContext, useState } from "react";

export const ChatStore = createContext([] as any[]);
export const PlayerBalance = createContext([] as any[]);
export const JackpotStore = createContext([] as any[]);
// export const ConnectionId = createContext([] as any[]);

export default function Store({ children }) {
  const [messages, setMessages] = useState([]);
  const [playerBalance, setPlayerBalance] = useState([]);
  const [totalJackpot, setTotalJackpot] = useState(null as any);

  return (
      <ChatStore.Provider value={[messages, setMessages]}>
        <PlayerBalance.Provider value={[playerBalance, setPlayerBalance]}>
          <JackpotStore.Provider value={[totalJackpot, setTotalJackpot]}>
            {children}
          </JackpotStore.Provider>
        </PlayerBalance.Provider>
      </ChatStore.Provider>

  );
}
