import { observable, action } from "mobx";
export interface socketStateTypes {
    ready: 0;
    working: 1;
    error: 3;
    serverNotice: 4;
}
export class ChatMessagesClass {
    @observable state = {
        messages: [] as any[],
    };

    @action setMessages = (messages: any[]) => {
        this.state.messages = messages;
      //  console.log(this.state.messages);
    };
}

export class GameStateClass {
    @observable state = {
        players: 0,
        jackpot: 2.5,
        lastQuestion: [],
    };

    @action setPlayers = (players: number, jackpot: number) => {
        if (jackpot <= 2.5) {
            jackpot = 2.5;
        }
        this.state.players = players;
        this.state.jackpot = jackpot;
    };

    // @action getTotalPlayers = () => {
    //     return this.state.players;
    // };
}

export class SocketStateClass {
    @observable connection = {
        type: 0,
        message: "",
    };

    @action working = () => {
        this.connection.type = 1;
    };

    @action error = (message: string) => {
        this.connection.type = 3;
        this.connection.message = message;
    };

    @action serverNotice = (message: string) => {
        // (this.connection.type = 4), (this.connection.message = message);
    };

    @action ready = () => {
        this.connection.type = 0;
    };
}

export const GameState = new GameStateClass();
export const SocketState = new SocketStateClass();
export const ChatMessages = new ChatMessagesClass();
