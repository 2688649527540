import React, { Fragment, useContext, useEffect, useState } from "react";
import { getServerType } from "../../Config";
import ApiService from "../../services/ApiService";
import { formatMoney } from "../../services/HelperMethodsService";
import { JackpotStore, PlayerBalance } from "../../store/Store";
import { toast } from "react-toastify";

export default function AvailableMoney() {
  const [balance, setBalance] = useContext(PlayerBalance);
  const [jackpot] = useContext(JackpotStore);
  const apiService = new ApiService();

  useEffect(() => {
    checkMoney();
  },[jackpot]);

  async function checkMoney() {
    var balStatus = sessionStorage.getItem("Balsts");
    var bal;
    
    if (balStatus == null) 
    {
      let data = (await apiService.getBalance()) as any;
      console.log(data); 
      if(data.data.errorCode != null)
      {
        toast.error(data?.data?.errorMsg);
        return;
      }
      sessionStorage.setItem("Balsts", "True");
      bal = data.data.balanceData.balances[0].amount;
    } 
    else 
    {
      let data = (await apiService.getBalanceDB()) as any;
      console.log(data); 
      bal = data.data;
    }
    
    setBalance((bal/100).toFixed(2));
  }

  if (getServerType() === 'playtech') {
    return (
      <div className="available-money-wrap text-white">
        <span>{formatMoney(balance)}</span>
      </div>
    )
  }

  return (
    <div className="available-money-wrap text-white">
      <span>{formatMoney(balance)}</span>
    </div>
  );
}
