/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateRolloutQuizzesSession = /* GraphQL */ `subscription OnCreateRolloutQuizzesSession($id: String) {
  onCreateRolloutQuizzesSession(id: $id) {
    id
    createdDate
    status
    entryCountTime
    lastQuestion
    lastAnswers
    lastCorrectAnswer
    questionCountTimer
    jackpot
    totalPlayers
    serverName
    gamePlayersStatus
    currentQuestionNumber
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRolloutQuizzesSessionSubscriptionVariables,
  APITypes.OnCreateRolloutQuizzesSessionSubscription
>;
export const onUpdateRolloutQuizzesSession = /* GraphQL */ `subscription OnUpdateRolloutQuizzesSession($id: String) {
  onUpdateRolloutQuizzesSession(id: $id) {
    id
    createdDate
    status
    entryCountTime
    lastQuestion
    lastAnswers
    lastCorrectAnswer
    questionCountTimer
    jackpot
    totalPlayers
    serverName
    gamePlayersStatus
    currentQuestionNumber
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRolloutQuizzesSessionSubscriptionVariables,
  APITypes.OnUpdateRolloutQuizzesSessionSubscription
>;
export const onDeleteRolloutQuizzesSession = /* GraphQL */ `subscription OnDeleteRolloutQuizzesSession($id: String) {
  onDeleteRolloutQuizzesSession(id: $id) {
    id
    createdDate
    status
    entryCountTime
    lastQuestion
    lastAnswers
    lastCorrectAnswer
    questionCountTimer
    jackpot
    totalPlayers
    serverName
    gamePlayersStatus
    currentQuestionNumber
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRolloutQuizzesSessionSubscriptionVariables,
  APITypes.OnDeleteRolloutQuizzesSessionSubscription
>;
