import React, { useContext, useState } from "react";
import { routes } from "../routes";
import { toast, ToastContainer } from "react-toastify";

import "./Main.scss?v1";
import ConnectionMessage from "../components/ConnectionMessage/ConnectionMessage";
import ExitQuizButton from "../components/ExitQuizButton";
import { Fragment, useEffect } from "react";
import ApiService from "../services/ApiService";
import {
  getAuthUser,
  getCurrentIP,
  setAuthUser,
  urlParamsAsync,
  urlParamsPlaytechAsync,
} from "../services/HelperMethodsService";
import RotateDeviceMessage from "../components/RotateDeviceMessage";
import { getServerType } from "../Config";

function Main() {
  const apiService = new ApiService();
  const [serverStatus, setServerStatus] = React.useState("connecting"); //connecting
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isOffline, setIsOffline] = useState(false);

  //check user online sts
  useEffect(() => {
    function onlineHandler() {
      console.log("isOnline")
      setIsOnline(true);
    }

    function offlineHandler() {
      console.log("isOffline")
      setIsOnline(false);
      setIsOffline(true);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  // capture closebtn event
  useEffect(() => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return apiService.closeGame().then((data: any) => {
        console.log("close game", data);
      });
    });
  }, []);

  //block page refresh
  useEffect(() => {
    document.addEventListener('keydown', (e) => {
        if(e.key=='F5')
        {
          //console.log('f5 pressed')
          e.preventDefault();
        }
        if(e.key =='r' && e.ctrlKey)
        {
          //console.log('ctrl + r pressed')
          e.preventDefault();
        }
    });
  }, [])
  
  useEffect(() => {
    if (isOffline) {
      apiService.closeGame().then((data: any) => {
        console.log(data);
      });
    }
  }, [isOnline]);
  
  useEffect(() => {
    if (getServerType() === "playtech") {
      playtechInit();
    } else {
      //check if 50/50 request
      let urlParams = urlParamsAsync();

      if (
        urlParams.gameType == "23006" ||
        urlParams.gameType == "23007" ||
        urlParams.gameType == "23008" ||
        urlParams.gameType == "23009" ||
        urlParams.gameType == "23010"
      ) {
        let fiftyURL = window.location.href;
        fiftyURL = fiftyURL.replace(
          "https://888.catmonocle.cloud/",
          "https://fifty.catmonocle.cloud/"
        );
        window.location.replace(fiftyURL);
      } else {
        init();
      }
    }
  }, []);

  async function playtechInit() {
    try {
      let urlParams = urlParamsPlaytechAsync();
       console.log('urlParamsPlaytechAsync', urlParams);
      const token = urlParams.secureToken;
      const playerId = urlParams.playerId;
      const gameId = urlParams.gameId;
      const name = urlParams.name;
      const skinId = urlParams.skinId;
      const localeCode = urlParams.localeCode;
      const clientPlatform = urlParams.clientPlatform;
      const clientType = urlParams.clienttype;
      var result = await apiService.authPlaytech({
        token,
        playerId,
        gameId,
        name,
        skinId,
        localeCode,
        clientPlatform,
        clientType,
      });
      console.log("playtechresult", result);
      setAuthUser(result?.data);
      setServerStatus("connected");
    } catch (error) {
      let msg = error?.response?.data?.message;
      console.log("auth error", error);
      if (!msg) {
        msg =
          "Error-009. An incorrect parameter was received by the server. Please close the game and try again.";
      }
      toast.error(msg, { toastId: "009" });
    }
  }

  async function init() {
    try {
      let urlParams = urlParamsAsync();
      const currentIp = (await getCurrentIP()) as any;
      urlParams.clientIp = currentIp.clientIp;
      console.log("init with", urlParams);
      var result = await apiService.auth(urlParams);
      console.log("auth", result?.data);
      setAuthUser(result?.data);
      setServerStatus("connected");
    } catch (error) {
      let msg = error?.response?.data?.message;
      console.log("auth error", error);
      if (!msg) {
        msg =
          "Error-009. An incorrect parameter was received by the server. Please close the game and try again.";
      }
      toast.error(msg, { toastId: "009" });
      return;
    }
    var preURL = document.referrer;
    if (!preURL.includes("888")) {
      localStorage.setItem("bingoURL", document.referrer);
    }
  }

  return (
    <>
      <ToastContainer />
      <ConnectionMessage />
      <RotateDeviceMessage />
      {serverStatus == "connected" ? (
        <div className="app-container">{routes()}</div>
      ) : (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          {serverStatus == "connecting" ? (
            <div>
              <img src={"/assets/logo.png"} width={80} />
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <div className="disconnect-msg">
                <p>
                  Error-006. You have been disconnected from the game. This is
                  possibly due to a lack of activity, or due to a problem with
                  your connection.
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <button
                    className="reload-btn"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Reload
                  </button>
                  <div className="exit" style={{ marginTop: 15 }}>
                    <ExitQuizButton>
                      <strong>Exit Quiz</strong>
                    </ExitQuizButton>
                  </div>
                </div>
              </div>
              <div className="app-container">{routes()}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Main;
