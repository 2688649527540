//TODO: Remove reference



import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function ExitQuizButton(props: { children?}) {
  const [showExist, setShowExit] = useState(true);

  useEffect(() => {
    if (window.opener && window.opener !== window) {
      setShowExit(false);
    }
  }, []);

  if (!showExist) {
    return <div></div>;
  }
  return (
    <div>
      {/* <button
        style={{
          border: "none",
          margin: 0,
          padding: 0,
          background: "transparent",
        }}
        onClick={() => {
          var url = localStorage.getItem("bingoURL");
          // console.log("url", url);
          if (url) window.location.href = url + "/client/#/home";
        }}
      >
        {props.children ? (
          props.children
        ) : (
          <span className="exit-quiz-button">
            Exit <br /> Quiz
          </span>
        )}
      </button> */}
    </div>
  );
}
