import React, { useContext, useEffect, useState } from "react";
import MessageList from "../../components/ChatArea/MessageList";
import "./GameEnd.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import SendMessage from "../../components/ChatArea/SendMessage";
import {
  formatMoney,
  formatScore,
  leftPad,
  getSkinId,
  urlParamsingle,
} from "../../services/HelperMethodsService";
import BackgroundColor from "../../components/BackgroundColor/BackgroundColor";
import CurveWhite from "../../components/CurveWhite/CurveWhite";
import PlayerCount from "../../components/PlayerCountArea/PlayerCount";
import ExitQuizButton from "../../components/ExitQuizButton";
import ConnectionInfo from "../../components/ConnectionInfo";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";

declare var window: any;

export default function GameEnd() {
  let timeoutHandler = null;

  const [state, setState] = useState({
    finalScore: 0,
    tier: 0,
    winPrice: 0,
    position: 0,
    redirectToEntry: true,
    redirectToPlayAgain: false,
    windowWidth: window.innerWidth,
    top3Players: [],
    totalPlayers: 0,
    connectionId: "",
  });

  let gameType = getSkinId();
  //var gameType = "hollywood";
  const apiService = new ApiService();
  const { gameId } = useParams() as any;
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getGameStatus();
  }, []);

  const handlePlayAgainClick = () => {
    setIsModalOpen(true);
  };

  const handleContinue = () => {
    gameRealityCheck("reset");
    setIsModalOpen(false);
    history({
      pathname: "/",
      search: new URL(window.location.href).search,
    });
  };

  const handleClose = () => {
    gameRealityCheck("stopgaming");
    setIsModalOpen(false);
    history({
      pathname: "/close",
      search: new URL(window.location.href).search,
    });
  };

  async function gameRealityCheck(userAction: string) {
    await apiService.realityCheck({ userAction }).then((data: any) => {
      console.log(data.data);
    });
  }

  async function getGameStatus() {
    setIsLoading(true);
    apiService.getGameStatus(gameId).then((data: any) => {
      data = data.data;
      if(data.errorCode =="ERR3002" || data.errorCode =="ERR008")
        {
          toast.error(data.errorMessage);
        }
      console.log("game end", data);
      setIsLoading(false);
    });
  }

  async function closeGame() {
    await apiService.closeGame().then((data: any) => {
      if (data.data) {
        window.close();
      }
    });
  }

  useEffect(() => {
    // console.log("gamid", gameId);
    apiService.getGameEndData(gameId).then((endData: any) => {
      console.log("enddata", endData);
      endData = endData.data;

      setState({
        ...state,
        finalScore: endData.finalScore,
        position: endData.position,
        tier: endData.tier,
        winPrice: endData.winPrice,
        totalPlayers: endData.totalPlayers,
        redirectToEntry: false,
        top3Players: endData.top3Players,
      });

      window.setTimeout(() => {
        if (endData.winPrice > 0) {
          window.startConfetti();
          window.setTimeout(() => {
            window.stopConfetti();
          }, 3500);
        }
      }, 100);

      // console.log(getScore(state.top3Players, 1));
      // console.log(getScore(state.top3Players, 2));
      // console.log(getScore(state.top3Players, 3));

      if (
        getScore(state.top3Players, 1) == "0000" &&
        getScore(state.top3Players, 2) == "0000" &&
        getScore(state.top3Players, 3) == "0000"
      ) {
        toast(
          "The player with the most points wins the biggest share of the prize fund. When players have zero points they also won't receive any prize."
        );
      }
    });

    window.onresize = () => {
      setState({ ...state, windowWidth: window.innerWidth });
    };

    return () => {
      if (timeoutHandler) {
        clearInterval(timeoutHandler);
      }
    };
  }, []);

  function position() {
    if (state.position != 0) {
      return <p className="text-yellow">Top {state.position}%</p>;
    }
  }

  return (
    <div className={"game-end"}>
      <BackgroundColor gameType={gameType}>
        <div className="player-count-end">
          <PlayerCount playerCount={state.totalPlayers} />
        </div>
        <div style={{ position: "absolute", zIndex: 99 }}>
          <ExitQuizButton />
        </div>
        <div className="game-end-grid">
          <div className="first-place place animate__animated animate__delay-2s animate__zoomInDown">
            <div className="name">{getName(state.top3Players, 1)}</div>
            <img src="/assets/images/firstplace.png" />
            <div className={"score n" + gameType}>
              {getScore(state.top3Players, 1)}
            </div>
          </div>
          <div className="second-place place animate__animated animate__delay-1s animate__zoomInDown">
            <div className="name">{getName(state.top3Players, 2)}</div>
            <img src="/assets/images/secondplace.png" />
            <div className={"score n" + gameType}>
              {getScore(state.top3Players, 2)}
            </div>
          </div>
          <div className="third-place place animate__animated  animate__zoomInDown">
            <div className="name">{getName(state.top3Players, 3)}</div>
            <img src="/assets/images/thirdplace.png" />
            <div className={"score n" + gameType}>
              {getScore(state.top3Players, 3)}
            </div>
          </div>

          <div className="stage">
            <div className="podium">
              <div className="img" />
            </div>

            <div className={"mobile-score"}>
              <div className="row">
                <div className="col-4 mb-1 text-center">
                  <div className="name second">
                    {getName(state.top3Players, 2)}
                  </div>
                  <div className="score">{getScore(state.top3Players, 2)}</div>
                </div>
                <div className="col-4 mb-1 text-center">
                  <div className="name first">
                    {getName(state.top3Players, 1)}
                  </div>
                  <div className="score">{getScore(state.top3Players, 1)}</div>
                </div>
                <div className="col-4 mb-1 text-center">
                  <div className="name third">
                    {getName(state.top3Players, 3)}
                  </div>
                  <div className="score">{getScore(state.top3Players, 3)}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="white-area">
            <div className="game-end-char">
              {/* <img
                className={"character-image"}
                src="/assets/images/game-end-char.png"
              /> */}
              {/* <CharacterImage gameType={gameType} /> */}
            </div>
            <CurveWhite>
              <div className="score-wrap">
                <div className={"score-content n" + gameType}>
                  <h2 className={"n" + gameType}>Your Score</h2>
                  <span className={"score n" + gameType}>
                    {formatScore(state.finalScore)}
                  </span>
                </div>
                <div className={"score-content winning n" + gameType}>
                  <h2 className={"n" + gameType}>Your Winnings</h2>
                  <span className={"score n" + gameType}>
                    {formatMoney(state.winPrice)}
                  </span>
                </div>
                <div className="button-row">
                  {/* <button
                    className={"link"}
                    onClick={() => {
                    getGameType();               
                    }}
                  >
                    <div className={"btn singleplayer-btn n" + gameType}>
                      <div className="text">TRY OUR A/B QUIZ</div>
                     <div className="up-img"></div>
                    </div>
                  </button>
                  <div className={"text-or n" + gameType}>OR</div> */}
                  <button className={"link"} onClick={handlePlayAgainClick}>
                    <div className={"btn lobby-btn n" + gameType}>
                      <div className="text">Play Again</div>
                      <img src="/assets/images/arrow_back.png" />
                    </div>
                  </button>
                  {isModalOpen && (
                    <div className="modal-overlay">
                      <div className="modal-pop">
                        <div className="modal-content-pop">
                          <div className="text">
                            Do you wish to continue playing the game?
                          </div>
                          <div className="modal-buttons">
                            <div
                              className={"btn lobby-btn n" + gameType}
                              onClick={handleContinue}
                            >
                              <div className="text-btn">CONTINUE</div>
                            </div>
                            <div
                              className={
                                "btn lobby-btn n" + gameType
                              }
                              onClick={handleClose}
                            >
                              <div className="text-btn">CLOSE</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={"text-or display-none n" + gameType}>OR</div>
                  <button
                    className={"link"}
                    onClick={() => {
                      closeGame();
                    }}
                  >
                    <div className={"btn display-none lobby-btn n" + gameType}>
                      <div className="text">Quit</div>
                      <div className="quit-img"></div>
                    </div>
                  </button>
                </div>
              </div>
            </CurveWhite>
          </div>
          <div className="message-list">
            <MessageList />
          </div>

          <SendMessage gameType={gameType} />
        </div>
        <ConnectionInfo connectionId={state.connectionId} />
      </BackgroundColor>
    </div>
  );

  function getName(top3Players: any, position: number): React.ReactNode {
    position -= 1;

    let name = "...";
    if (top3Players[position]) {
      name = top3Players[position].name;
    }

    return name;
  }

  function getScore(top3Players: any, position: number): React.ReactNode {
    position -= 1;

    if (top3Players[position]) {
      let score = top3Players[position].score;
      return leftPad(score, 4);
    }

    return "0000";
  }
}
