import React, { Fragment, useContext, useEffect, useState } from "react";
import "./ChatArea.scss";
import { getServerName, strToColor } from "../../services/HelperMethodsService";
import { ChatStore } from "../../store/Store";
import ApiService from "../../services/ApiService";

export default function MessageList() {
  // chatMessages = this.props.ChatMessages as ChatMessagesClass;
  const [messages, setMessages] = useState([] as any[]);
  const apiService = new ApiService();
  const chatTime = new Date();
  const [timer, setTimer] = useState(null as any);
  useEffect(() => {
    listenToChat(); // TODO: optimize chat
  }, []);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  function listenToChat() {
    try {
      apiService
        .getChat({ dateTime: chatTime, server: getServerName() })
        .then((data: any) => {
          data = data.data;

          if (data?.length) {
            setMessages(data);
          }
          let timer = setTimeout(() => {
            listenToChat();
          }, 10);
          setTimer(timer);
        });
    } catch (error) { 
      console.log(error, "Chat Error")
    }
  }

  function getNameFromUrl(){
    let url_string = window.location.href; //window.location.href
    let url = new URL(url_string);
    return  url.searchParams.get("DisplayName");
  }

  return (
    <div className="chat-text-area">
      {messages.map((msg: any, index: any) => {
        return (
          <div className="message-text" key={index}>
            <div>
              <span className="message-body float-right">{msg.message}</span>
            </div>
            <span
              className="name"
              style={{
                backgroundColor: strToColor(msg.name),
              }}
            >
              {msg.isMe ? (
                <span style={{}}>
                  <i className="fas fa-circle"></i>
                </span>
              ) : (
                <Fragment />
              )}{" "}
              {msg.name}
            </span>
          </div>
        );
      })}
    </div>
  );
}
