import { toast } from "react-toastify";
import { getServerType } from "../Config";

export function formatCountDown(time: number , type : number ) {
  if (!time) {
    return "00.00";
  }

  if(type==1)
  {
   return new Date(time * 1000).toISOString().substr(17, 2);  
  }
  return new Date(time * 1000).toISOString().substr(14, 5);
}

export function GameTypesList() {
  const n80s = "80s";
  const n90s = "90s";
  const n00s = "00s";
  const nMusic = "music";
  const nHollywood = "hollywood";

  return {
    n80s,
    n00s,
    n90s,
    nMusic,
    nHollywood,
  };
}

export function getSkinId() {
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string);
  var gType = url.searchParams.get("GameType");

  if(getServerType() === 'playtech'){
    gType = url.searchParams.get("game");
    if (gType == "HOL") {
      return "hollywood";
    }
    if (gType == "MUS") {
      return "music";
    }
    if (gType == "00S") {
    return "00s";
    }
    if (gType == "90s") {
      return "90s";
    }
    if (gType == "80s") {
      return "80s";
    }

    return 'hollywood';
  }

  if (gType == "HOL") {
    return "hollywood";
  }
  if (gType == "80s") {
    return "80s";
  }
  if (gType == "90s") {
    return "90s";
  }
  if (gType == "00S") {
    return "00s";
  }
  if (gType == "MUS") {
    return "music";
  }

  return "";
}

export function getServerName() {
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string);
  var gType = url.searchParams.get("GameType");

  if(getServerType() === 'playtech'){
    gType = url.searchParams.get("game");
    if (gType == "HOL") {
      return "Hollywood";
    }
    if (gType == "MUS") {
      return "Music";
    }
    if (gType == "00S") {
    return "Noughties";
    }
    if (gType == "90s") {
      return "Nineties";
    }
    if (gType == "80s") {
      return "Eighties";
    }

    return "Hollywood";
  }

  if (gType == "HOL") {
    return "Hollywood";
  }
  if (gType == "80s") {
    return "Eighties";
  }
  if (gType == "90s") {
    return "Nineties";
  }
  if (gType == "00S") {
    return "Noughties";
  }
  if (gType == "MUS") {
    return "Music";
  }

  return "";
}

export function handleErrors(error) {
  if (error.response?.data?.message) {
    toast.error(error.response?.data?.message);
  } else if (error?.message) {
    toast.error(error?.message);
  } else {
    toast.error("Request failed!");
  }
}

export function urlParamsPlaytechAsync() {
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string) as any;
  const args =  new URLSearchParams(url.search);
  console.log('args',url.search,  args.get('Token'));
  return {
    secureToken: args.get("token"),
    playerId: args.get("username"),
    gameId: args.get("game"),
    name: url.searchParams.get("username"),
    skinId:url.searchParams.get("casino"),
    localeCode:url.searchParams.get("language"),
    clientPlatform:url.searchParams.get("clientplatform"),
    clienttype:url.searchParams.get("clienttype"),
    search: url.search,
  };
}

export function urlParamsingle() {
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string);

  return {
    token: url.searchParams.get("Token"),
    gameType: url.searchParams.get("GameType"),
    networkID: url.searchParams.get("NetworkID"),
    playerID: url.searchParams.get("PlayerID"),
    serverName: getServerName().toString(),
    name: url.searchParams.get("DisplayName"),
    cashierURL : url.searchParams.get("cashierURL"),
    SkinId : url.searchParams.get("SkinId"),
    RealityCheckFrequency : url.searchParams.get("RealityCheckFrequency"),
    country : url.searchParams.get("country"),
    GameCurrency : url.searchParams.get("GameCurrency"),


  };
}

export function urlParamsAsync() {
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string);
  return {
    token: url.searchParams.get("Token"),
    gameType: url.searchParams.get("GameType"),
    networkID: url.searchParams.get("NetworkID"),
    playerID: url.searchParams.get("PlayerID"),
    serverName: getServerName().toString(),
    name: url.searchParams.get("DisplayName"),
    clientIp: '',
  };
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function validateBot(callBack: CallableFunction, actionName: string) {
  callBack();
  // let grecaptcha = (window as any).grecaptcha;

  // grecaptcha.ready(function () {
  //   grecaptcha
  //     .execute("6LeU7qcaAAAAAGh0Dbj_oxtuapuOSbO1eED_ODb9", {
  //       action: actionName,
  //     })
  //     .then(function (token) {
  //       callBack(token);
  //     })
  //     .catch((err) => {
  //       console.log("gr error", err);
  //       alert(
  //         "We are unable to load Google reCAPTCHA!. Please reload the game"
  //       );
  //     });
  // });
}

export function validateBotEntry(
  callBack: CallableFunction,
  actionName: string
) {
  callBack("incorrect token");
  // let grecaptcha = (window as any).grecaptcha;

  // grecaptcha.ready(function () {
  //   grecaptcha
  //     .execute("6LdiRy8kAAAAANsgSybFjf0wIBFGB0DDClEuwyY6", {
  //       action: actionName,
  //     })
  //     .then(function (token) {
  //       callBack(token);
  //     })
  //     .catch((err) => {
  //       console.log("gr error", err);
  //       alert(
  //         "We are unable to load Google reCAPTCHA!. Please reload the game"
  //       );
  //     });
  // });
}

let currentIp = "";
export async function getCurrentIP() {
 // console.log("current ip", currentIp);

  if (!currentIp) {
    let jQuery = (window as any).$;
    return new Promise((resolve, reject) => {
      jQuery.getJSON(
        "https://888.catmonocle.cloud/auth/ip",
        (data) => {
          let ip = data.ip;
          currentIp = ip;
          resolve({
            clientIp: ip,
          });
        },
        () => {
          reject("failed to get ip address");
        }
      );
    });
  }
  return new Promise((resolve, reject) => {
    return resolve({
      clientIp: currentIp,
    });
  });
}

export function strToColor(str) {
  if (!str) {
    return "";
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

export function formatMoney(value: number | string) {

  if(typeof value === 'string'){
    value = parseFloat((value as string).replace(/,/g, ''));
  }
  
  var val = value?.toLocaleString("en-US", {
    style: "currency",
    currency: "GBP",
  });

  if (val?.includes("NaN")) {
    val = "...";
  }

  return val;
}

export function formatScore(value: number) {
  // console.log(value,"score value")
  if (value == null && value < 0) {
    value = 0;
  }
  return ("0000" + value).slice(-4);
}

export function warnRefresh() {
  window.onbeforeunload = (e: any) => {
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };
}

export function removeRefreshWarn() {
  window.onbeforeunload = function () {
    // blank function do nothing
  };
}

export function leftPad(number: number, targetLength: number) {
  var output = number + "";
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return output;
}

export function getAuthUser(): any {
  let str = localStorage.getItem("auth");
  return str;
}

export function getUserId() {
  return localStorage.getItem("userId");
}
export function setAuthUser(auth: any) {
  localStorage.setItem("auth", auth.jwt);
  if (auth.playerId) {
    localStorage.setItem("playerId", auth.playerId);
  }
  if (auth.userId) {
    localStorage.setItem("userId", auth.userId);
  }
}
