import axios from "axios";
import Config from "../Config";
import { getAuthUser } from "./HelperMethodsService";

export default class ApiService {
  async authPlaytech(params: { token, playerId, gameId ,name,skinId,localeCode,clientPlatform,clientType
  }) {
    let ax = await this.ax();
    return ax.post("/auth/playtech", params);
  }

  async auth(params) {
    let ax = await this.ax();
    return ax.post("/auth", params);
  }

  async registerPlayerForNextGame(data) {
    let ax = await this.ax();
    return ax.post("/Entry/RegisterForNextGame", data);
  }

  async getInitData(data) {
    let ax = await this.ax();
    return ax.post("/Entry/InitData", data);
  }

  async sendJokerPlay(gameId) {
    let ax = await this.ax();
    return ax.post("/Game/PlayJoker/" + gameId);
  }

  async getGamePlayers(gameId) {
    let ax = await this.ax();
    return ax.get("/Game/GamePlayers/" + gameId);
  }

  async currentQuestions(gameId) {
    let ax = await this.ax();
    return ax.get("/Game/CurrentQuestions/" + gameId);
  }

  async playerSendAnswer(data) {
    let ax = await this.ax();
    return ax.post("/Game/PlayerSendAnswer", data);
  }

  async getGameStatus(gameId: number) {
    let ax = await this.ax();
    return ax.get("/Game/GetGameStatus/" + gameId);
  }

  async getGameEndData(gameId) {
    let ax = await this.ax();
    return ax.get("/Game/FinalScore/" + gameId);
  }

  async getChat(data) {
    let ax = await this.ax();
    return ax.post("/chat/get", data);
  }

  async addChat(data) {
    let ax = await this.ax();
    return ax.post("/chat/add", data);
  }

  async getBalance() {
    let ax = await this.ax();
    return ax.get("/entry/PlayerBalance/");
  }
  
  async getBalanceDB() {
    let ax = await this.ax();
    return ax.get("/entry/PlayerBalanceDB/");
  }

  async gameLogout() {
    let ax = await this.ax();
    return ax.get("/Game/Logout");
  }

  async closeGame() {
    let ax = await this.ax();
    return ax.get("/auth/gameclose", );
  }
  
  async realityCheck(data) {
    let ax = await this.ax();
    return ax.post("/auth/realitycheck", data);
  }

  async ax() {
    let config = new Config();
    let url = config.getBaseURL();
    const jwt = getAuthUser();

    return axios.create({
      baseURL: url,
      timeout: 990000,
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    });
  }
}
