import { useContext, useEffect, useState } from "react";
import PlayerCount from "../../components/PlayerCountArea/PlayerCount";
import MessageList from "../../components/ChatArea/MessageList";
import SendMessage from "../../components/ChatArea/SendMessage";
import "./EntryScreen.scss";
import BackgroundColor from "../../components/BackgroundColor/BackgroundColor";
import CurveWhite from "../../components/CurveWhite/CurveWhite";
import {
  formatCountDown,
  formatMoney,
  getServerName,
  getSkinId,
  handleErrors,
  validateBotEntry,
} from "../../services/HelperMethodsService";
import CharacterImage from "./CharacterImage";
import { Fragment } from "react";
import ExitQuizButton from "../../components/ExitQuizButton";
import { toast } from "react-toastify";
import ConnectionInfo from "../../components/ConnectionInfo";
import ApiService from "../../services/ApiService";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { generateClient } from "aws-amplify/api";
import * as subscriptions from "../../graphql/subscriptions";
import { JackpotStore } from "../../store/Store";
import { Helmet } from "react-helmet";

export default function EntryScreen() {
  const history = useNavigate();
  const gameType = getSkinId();
  const [timer, setTimer] = useState(0);
  const [isEntered, setIsEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [totalJackpot, setTotalJackpot] = useContext(JackpotStore);
  const [startTime, setStartTime] = useState(0);
  const [appSyncGameId, setAppSyncGameId] = useState("");
  const [status, setStatus] = useState("");
  const [winStreakBlock, setWinStreakBlock] = useState(null as any);
  const [errorResultBlock, setErrorResultBlock] = useState(false);
  const apiService = new ApiService();
  const [sub, setSub] = useState(null as any);
  const client = generateClient();
  useEffect(() => {
    getInitData();
    console.log("loc", window.location.href);
  }, []);

  useEffect(() => {
    return () => {
      if (sub) {
        // console.log("unsubed entry");
        sub.unsubscribe();
      }
    };
  }, [sub]);

  useEffect(() => {
    if (appSyncGameId) {
      if (sub) {
        sub.unsubscribe();
      }
      getInitData();
      subscribeGame();
    }
  }, [appSyncGameId]);

  const toggleCaptchaBadge = (show: boolean) => {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? "visible" : "hidden";
    }
  };

  useEffect(() => {
    toggleCaptchaBadge(true);
    return () => toggleCaptchaBadge(false);
  }, []);

  let timeout;
  //timer
  useEffect(() => {
    if (startTime) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        var diff = moment(startTime).diff(moment(), "seconds");
        if (diff > 0) {
          setTimer(diff);
        } else {
          setTimer(0);
        }
      }, 1000);
    }
  }, [startTime, timer]);

  //status change
  useEffect(() => {
    if (isEntered && status == "Current") {
      history({
        pathname: "/game/" + gameType + "/" + appSyncGameId,
        search: new URL(window.location.href).search,
      });
    } else if (status == "Current") {
      setAppSyncGameId("");
      getInitData();
    } else if (status == "CancelledDueToNotEnoughPlayers") {
      if (isEntered) {
        toast.error(
          "Not enough players - Your account has been refunded! Please start another game"
        );
        setTotalJackpot(0);
      }
      setTotalPlayers(0);
      setIsLoading(false);
      setIsEntered(false);
      setAppSyncGameId("");
      getInitData();
    }
  }, [status]);

  async function subscribeGame() {

    const sub = client
      .graphql({
        query: subscriptions.onUpdateRolloutQuizzesSession,
        variables : {id : appSyncGameId}
      })
      .subscribe({
        next: (data) => {
          var startTime=
            data?.data?.onUpdateRolloutQuizzesSession?.entryCountTime as any;
          var jackpot =
            data?.data?.onUpdateRolloutQuizzesSession?.jackpot;
          var totalPlayers =
            data?.data?.onUpdateRolloutQuizzesSession?.totalPlayers  as number;
          var status = data?.data?.onUpdateRolloutQuizzesSession?.status  as string;
                   
          console.log("appsync",data?.data?.onUpdateRolloutQuizzesSession);

          setStartTime(startTime);
          setTotalJackpot(jackpot);
          setTotalPlayers(totalPlayers);
          setStatus(status);
        },
        error: (error) => console.log(error),
      });
    setSub(sub);
  }

  function getInitData() {
    apiService
      .getInitData({ serverName: getServerName() })
      .then(async (data: any) => {
        data = data.data;
        console.log(data, "init data");
        setStartTime(data.entryGameStartTime);
        setAppSyncGameId(data.lastGameAppSyncId);
        setWinStreakBlock(data.winStreakResult?.message);
        setErrorResultBlock(data.resultBlock);

        if (errorResultBlock) {
          toast.error("Please contact customer support!");
        }

        if (!appSyncGameId) {
          setTotalPlayers(data.totalPlayers);
          setTotalJackpot(data.totalJackpot);
        }

        //redirect if already in the game
        if (data.playerGameStatus == "Init") {
          setIsEntered(true);
        } else if (
          data.playerGameStatus == "Current" ||
          data.playerGameStatus == "Started"
        ) {
          history({
            pathname: "/game/" + gameType + "/" + data.playerGameAppSyncId,
            search: new URL(window.location.href).search,
          });
        }
      });
  }

  async function registerForNextGame() {
    setIsLoading(true);
    validateBotEntry(async (token) => {
      try {
        let result = (await apiService.registerPlayerForNextGame({
          gCaptcha: token,
          Server: getServerName(),
        })) as any;
        result = result.data;
        console.log(result);
        
        setIsEntered(true);
      } catch (error) {
        console.log(error);      
        handleErrors(error);
        setIsLoading(false);
      }
    }, "PlayGame");

    // entrySocketService.registerForNextGame(() => {
    //   setState({ isEntered: true });
    //   // warnRefresh();
    // });
  }

  function GetGameName() {
    var gameType = getServerName();

    switch (gameType) {
      case "Noughties": {
        return "00'S QUIZ";
      }
      case "Eighties": {
        return "80'S QUIZ";
      }
      case "Nineties": {
        return "90'S QUIZ";
      }
      case "Music": {
        return "MUSIC QUIZ";
      }
      case "Hollywood": {
        return "Hollywood Quiz";
      }
    }
  }

  function playButtonArea() {
    if (!isEntered) {
      return (
        <div className="play-area">
          <div className="count-wrap">
            <div className="name">
              <span>
                {GetGameName()}
                &nbsp;&nbsp;
              </span>
            </div>
            <div className="number price">
              <i className="fa fa-gift" />
              Prize Fund :{" "}
              {totalJackpot < 2.5
                ? formatMoney(2.5)
                : formatMoney(totalJackpot)}
            </div>
          </div>
          <div className="intro-text">
            Answer fast and correct to get to the top of the leaderboard. The
            highest scoring player wins the biggest share of the prize fund!
          </div>

          <div className="description">
            The count down clock shows how long there is until the next game
            starts. A game needs at least three players. If there aren't three
            people ready to start the game when the clock has counted down your
            stake will be refunded. Why don't you try the chat whilst you wait
            for the game to start?
          </div>

          <div className="play-back-wrap">
            <div className="d-flex align-items-center justify-content-between">
              {/* <Link to={"/"}>
                  <div className="back-button">
                      <i className="fas fa-angle-left" />
                  </div>
              </Link> */}
              <div className={"start-time-text"}>
                <div className="text">Start In:</div>
                <span>{formatCountDown(timer, 0)}</span>
              </div>
              {playButton()}
            </div>
          </div>
          {winStreakBlock && (
            <div className="server-notice">
              <div className="text">{winStreakBlock}</div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className={"play-area"}>
          <div className="count-area">
            <h2>GET READY</h2>
            <div className="number price">
              <i className="fa fa-gift mr-2" />
              <span>Prize Fund:</span>
              {totalJackpot < 2.5
                ? formatMoney(2.5)
                : formatMoney(totalJackpot)}
            </div>
          </div>
        </div>
      );
    }
  }

  function getImages() {
    if (!isEntered) {
      return <CharacterImage gameType={gameType} />;
    } else {
      return (
        <div className="character-clock-wrapper">
          <div className={"character-clock"}>
            <div className="count">
              {timer > 0 ? (
                formatCountDown(timer, 1)
              ) : (
                <div style={{ fontSize: 30 }}>
                  GET
                  <br />
                  READY
                </div>
              )}
            </div>
            <img className={"clock"} src={"/assets/images/clock.png"} />
          </div>
          <img className="shadow" src="/assets/images/countdown_shadow.png" />
        </div>
      );
    }
  }

  return (
    <div className={"entry-screen"}>
      <BackgroundColor gameType={gameType} showIcons={true}>
        <div
          className="row m-0 mt-1 justify-content-between"
          style={{ zIndex: isShowInfo ? 9991 : 5 }}
        >
          <ExitQuizButton />
          <div className="row m-0 mt-1 justify-content-end align-items-center">
            <div
              className="col-auto info-icon"
              onClick={() => {
                setIsShowInfo(!isShowInfo);
              }}
            >
              <i className="fas fa-info"></i>
            </div>
            <div className="col-auto">
              <PlayerCount playerCount={totalPlayers} />
            </div>
            <div>
              {isShowInfo ? (
                <div className="game-info">
                  <div className="title">HOW TO PLAY &amp; WIN:</div>
                  <div
                    className="close-btn"
                    onClick={() => {
                      setIsShowInfo(false);
                    }}
                  >
                    <i className="fas fa-times-circle"></i>
                  </div>
                  <p>
                    By entering the quiz, you accept to be bound by the
                    following rules:
                  </p>
                  <div>
                    <ul>
                      <li>The highest scores win a share of the prize fund.</li>
                      <li>The faster the answer, the more points scored.</li>
                      <li>Points are deducted for incorrect answers.</li>
                      <li>
                        The Joker doubles the points for a correct answer.
                      </li>
                      <li>This quiz is a real-money-only game.</li>
                      <li>
                        If you experience a disconnect, the quiz service will
                        attempt to rejoin you at the same question. Any
                        questions missed as a result of the disconnect will be
                        treated as incorrect answers and Cat Monocle Ltd, its
                        partners and affiliates shall not be liable for the
                        consequences of any disconnect regardless of the cause.
                      </li>
                      <li>
                        In the event of any dispute regarding the quiz,
                        including any dispute regarding the questions and
                        answers, the decision of Cat Monocle Ltd shall be final
                        and no correspondence shall be entered into.
                      </li>
                      <li>
                        Anyone believed to be cheating, collaborating or using
                        the internet or automated methods to obtain answers
                        shall be disqualified from the quiz.
                      </li>
                      <li>
                        Cat Monocle Ltd can amend these rules without notice.
                      </li>
                      <li>
                        The quiz and these rules shall be governed by the law of
                        England and Wales.
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <Fragment />
              )}
            </div>
          </div>
        </div>
        <MessageList />

        <div className="content-area">
          <CurveWhite>
            <div className="images">{getImages()}</div>
            <div>{playButtonArea()}</div>
          </CurveWhite>
        </div>
        <SendMessage gameType={gameType} />

        <ConnectionInfo connectionId={appSyncGameId} />
      </BackgroundColor>
    </div>
  );

  function playButton() {
    if (!timer && timer > 2) {
      return (
        <div
          className={
            winStreakBlock || errorResultBlock
              ? "play-button-hide"
              : "play-button"
          }
          style={{ backgroundColor: "#dfdfdf" }}
        >
          <div
            className="circle"
            style={{ backgroundColor: "#dfdfdf", color: "black" }}
          >
            <i className="fas fa-spinner fa-spin" />
          </div>
          <div className="gray-text pr-3">
            Refreshing Session.
            <br />
            Please wait a few seconds.
          </div>
        </div>
      );
    } else if (isLoading) {
      return (
        <div className={"play-button"}>
          <div className="circle">
            <i className="fas fa-spinner fa-spin" />
          </div>
        </div>
      );
    }
    return (
      <div
        className={
          winStreakBlock || errorResultBlock ? "wrapper hide" : "wrapper"
        }
      >
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js?render=6LdiRy8kAAAAANsgSybFjf0wIBFGB0DDClEuwyY6"></script>
        </Helmet>
        <div
          className={
            winStreakBlock || errorResultBlock
              ? "play-button-hide"
              : "play-button"
          }
          onClick={() => registerForNextGame()}
        >
          <div className="d-flex align-items-center">
            <div className="circle">
              <i className="fas fa-pound-sign" />
            </div>
            <div className="col-auto price">{formatMoney(1)}</div>
            <div className="text">
              <span>Play</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
